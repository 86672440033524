import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { RichText as PrismicRichText } from "prismic-reactjs";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import {
  Box,
  Button,
  FlexCol,
  FlexRow,
  H2,
  H6,
  Icon,
  PrismicImage,
  PrismicLink,
  SectionWrapper,
} from "src/components";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import { css } from "styled-components";

SwiperCore.use([Navigation]);

const useSwiperRef = () => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setWrapper(ref.current);
  }, []);

  return [wrapper, ref];
};

function CollageSlice({ background_color, items }) {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <SectionWrapper
      py={[6, 7, 8]}
      bg={background_color}
      maxWidth="100%"
      mx={0}
      px={0}
      style={{ position: "relative" }}
    >
      <Swiper
        slidesPerView={1.3}
        spaceBetween={32}
        navigation={{
          prevEl,
          nextEl,
        }}
        centeredSlides={true}
        loop={true}
        grabCursor={true}
        watchSlidesProgress={true}
        watchSlidesVisibility={true}
        breakpoints={{
          // when window width is >= 650px
          650: {
            slidesPerView: 1.3,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
          },
          // when window width is >= 990px
          990: {
            slidesPerView: 3,
          },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={`slide-${index}`}
            style={{
              display: "flex",
              height: "auto",
            }}
          >
            <FlexCol width="100%" justifyContent="center">
              <Box
                width="100%"
                pb={
                  (item.image.dimensions.height / item.image.dimensions.width) *
                    100 +
                  "%"
                }
                style={{ position: "relative" }}
              >
                <PrismicImage
                  image={item.image}
                  objectFit="cover"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: 0,
                    right: 0,
                  }}
                />
              </Box>
            </FlexCol>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box position="absolute" top="50%" left={[6, 8, 10]} zIndex={2}>
        <button
          ref={prevElRef}
          css={`
            cursor: pointer;
            border: none;
            transform: translateY(-50%);
            height: 48px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon
            symbol={mdiArrowLeft}
            color="white"
            css={`
              width: 32px !important;
              height: 32px !important;
            `}
          />
        </button>
      </Box>
      <Box position="absolute" top="50%" right={[6, 8, 10]} zIndex={2}>
        <button
          ref={nextElRef}
          css={`
            cursor: pointer;
            border: none;
            transform: translateY(-50%);
            height: 48px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Icon
            symbol={mdiArrowRight}
            color="white"
            css={`
              width: 32px !important;
              height: 32px !important;
            `}
          />
        </button>
      </Box>
    </SectionWrapper>
  );
}

export default CollageSlice;
